/* Three divisions */

#headerbar {
  background: "#B891FF";
  top: 0;
  z-index: 100000000;
  padding: 0.55%;
}

#inboxheaderbar {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 100000000;
  padding: 0.25%;
  border-bottom: 1px solid #e5e5e5;
}