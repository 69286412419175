a {
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

a:visited {}

a:hover {
  text-size-adjust: 100%;
}

.bluelink {
  color: #8A54EA !important;
  cursor: pointer;
}

.bluelink:hover {
  color: #996be9 !important;
}

.buttonlink:hover {
  background-color: transparent !important;
}

.bluebutton {
  border-radius: 50px;
  padding: 12.5px;
  background: #a675ff !important;
  color: white;
  font-weight: bold;
  font-size: 80%;
  padding-left: 25px;
  padding-right: 25px;
}

.bluebutton:hover {
  background: #af87f9 !important;
  color: white !important;
}

.bluebuttonhollow {
  border-radius: 50px;
  padding: 10px;
  background: transparent !important;
  border: 2px solid #a675ff !important;
  color: #a675ff !important;
  font-weight: bold;
  font-size: 80%;
  padding-left: 16px;
  padding-right: 16px;
}

.bluebuttonhollow:hover {
  border: 2px solid #af87f9;
  color: #af87f9 !important;
  background: transparent !important;
}

.whitebutton {
  border-radius: 50px;
  padding: 12.5px;
  background: white;
  border: 2px solid white !important;
  color: #000 !important;
  font-weight: bold;
  font-size: 80%;
}

.whitebutton:hover {
  border: 2px solid #f4f4f4;
  background: #f4f4f4 !important;
  color: #000 !important;
}


.blackbutton {
  border-radius: 50px;
  padding: 12.5px;
  background: #101010;
  border: 2px solid #101010;
  color: #A675FF;
  font-weight: bold;
  font-size: 80%;
}

.blackbutton:hover {
  border: 2px solid #202020;
  background: #202020 !important;
  color: #A675FF !important;
}

.whitebuttonhollow {
  border-radius: 50px;
  padding: 10px;
  background: transparent;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 70%;
}

.whitebuttonhollow:hover {
  background: transparent !important;
}

.blackbuttonhollow {
  border-radius: 50px;
  padding: 10px;
  background: transparent !important;
  border: 2px solid black;
  color: black;
  font-weight: bold;
  font-size: 70%;
}

.blackbuttonhollow:hover {
  color: black !important;
}

.whitebuttonhollow:hover {
  border: 2px solid #e5e5e5;
  color: #e5e5e5;
  background: transparent !important;
}


.graylink {
  color: #808080;
}

.graylink:hover {
  color: #fff;
}

.whitehover:hover {
  background-color: #e5e5e5 !important;
}

.darkhover:hover {
  background-color: #3d3943 !important;
}