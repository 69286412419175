.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 12.5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 80%;
  text-align: left;
  font-weight: bold;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.25);
  top: 100%;
  z-index: 100000;
}


.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  color: #000;
  padding: 5%;
  text-decoration: none;
  padding-left: 10%;
  border-bottom: 1px solid #e5e5e5;

}

.dropdown-content a:hover {
  color: #ffffff !important;
  background-color: #a675ff;
}



.dropdownbtn {
  border-radius: 12.5px;
  color: black !important;
  background: #fff !important;
  font-size: 80%;
  font-weight: bold;
  padding-right: 7.5%;
  padding-left: 7.5%;
  border: 1px solid #e5e5e5;
}

.dropdownbtndark {
  border-radius: 12.5px;
  color: white !important;
  background: #333038 !important;
  font-size: 80%;
  padding-right: 7.5%;
  padding-left: 7.5%;
}


.dropdown-content-dark {
  display: none;
  position: absolute;
  width: 100%;
  overflow: auto;
  background-color: #333038;
  border-radius: 12.5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 80%;
  text-align: left;
  font-weight: bold;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.25);
  top: 100%;
  z-index: 100000;
}


.dropdown-content-dark a {
  display: block;
  color: #fff;
  padding: 5%;
  text-decoration: none;
  padding-left: 10%;
  border-bottom: 1px solid #3d3943;

}

.dropdown-content-dark a:hover {
  color: #ffffff;
  background-color: #a675ff;
}

.dropdown:hover .dropdown-content-dark {
  display: block;
}


.dropdownbtn:hover {}