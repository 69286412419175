* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
}

body {
  margin-top: 0 !important;
  padding-top: 0 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: white;
  /*   background-image: url("../../3.images/loading.gif");
 */
  background-size: 4%;
  background-repeat: no-repeat;
  background-position: center center;
  /* background: #111111; */
  color: black;
  overflow-x: hidden !important;
}

p {}

ul {
  list-style: none;
  /* Remove default bullets */
  font-family: "Open Sans", "system-ui";

}

ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #606060;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
  font-size: 110%;
}


.vertcenter {
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  /* More style: */
}

.bighover:hover {
  font-size: 110%;
  padding: 14px;
}

.pointer {
  position: relative;
  top: 0;
  transition: top ease 0.1s;
  cursor: pointer;
}

.pointer:hover {}

.newvertcenter {
  position: relative;
}

.newvertcenterelement {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.onlyvertcenter {
  display: flex;
  align-items: center;
}

.containerdivs {
  background: #2c2c2c;
  border: 1px solid #808080;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.267);
  border-radius: 12.5px;
}

.smalldivs {
  border: 1px solid #808080;
}

#loading {
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

#upperinnermobile {
  font-size: 105%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

table,
td,
tr,
th {
  outline: none !important;
  border: none !important;
}


.wrapper {
  background-color: #f4f4f4;
  border-radius: 50px;
}

.container {
  border-radius: 50px;
}

.barCompleted {
  background-color: #7b3fe4;
  border-radius: 50px;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.composeTinyButtons {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

.composeTinyButtons:hover {
  background-color: #c6a8f9;
}

.tinyButtons {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

.tinyButtons:hover {
  background-color: #d0d0d0;
}

.tinyButtonsDark {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

.tinyButtonsDark:hover {
  background-color: #d0d0d0;
}


.inboxtab {
  cursor: pointer;
}

.inboxtab:hover {
  cursor: pointer;
  background-color: #e5e5e5 !important;
}

.inboxtabdark:hover {
  cursor: pointer;
  background-color: #202020 !important;
}

a {
  cursor: pointer;
}



.composebtn:hover {
  background-color: #af87f9 !important;
}



.marquee {
  width: 100%;
  line-height: 50px;
  background-color: #a675ff;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee p {
  display: inline-block;
  /* padding-left: 100%;
  animation: marquee 30s linear infinite; */
}

.hover {
  border-radius: 12.5px;
  padding: 2%;
  padding-left: 4%;
}

.hover:hover {
  background-color: #e5e5e5;
}

.ticker {
  width: 100%;
}