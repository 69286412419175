.spinner {
  animation: spin infinite 5s linear;

  /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tabs {
  font-weight: bold;
  font-size: 80%;
  border-radius: 12.5px
}

.tabsSelected {
  background-color: #f4f4f4 !important;
  color: black !important;
}

.tabsUnselected {
  background-color: transparent !important;
  color: black !important;
}

.tabsSelectedDark {
  background-color: #333038 !important;
  color: white !important;
}

.tabsUnselectedDark {
  background-color: transparent !important;
  color: white !important;
}

.tabsSelected:hover {
  background-color: #f4f4f4 !important;
}

.tabsUnselected:hover {
  border: 2px solid #f4f4f4 !important;
}

.tabsSelectedDark:hover {}

.tabsUnselectedDark:hover {
  border: 2px solid #3d3943 !important;
}

.tableRow {
  padding: 1.5%;
  border-radius: 12.5px;
  margin-bottom: 2%;
  background-color: #f4f4f4;
}

.tableRowDark {
  padding: 1.5%;
  border-radius: 12.5px;
  margin-bottom: 2%;
  background-color: #333038;

}

.tableRow:hover {
  background-color: #e5e5e5;
}

.tableRowDark:hover {
  background-color: #3d3943;
}


.box {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border: 1px solid #e5e5e5;
}

.box:hover {
  box-shadow: rgba(9, 30, 66, 0.125) 0px 4px 8px -2px, rgba(9, 30, 66, 0.04) 0px 0px 0px 1px;
  transform: translate(0, -5px);
  transform: translate3d(0, -5px, 0);
}


.boxDark {
  box-shadow: rgba(50, 50, 50, 0.25) 0px 4px 8px -2px, rgba(50, 50, 50, 0.08) 0px 0px 0px 1px;
  border: 1px solid #3d3943;
}

.boxDark:hover {
  transform: translate(0, -5px);
  transform: translate3d(0, -5px, 0);
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #e5e5e5;

}

.shadowDark {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 2px solid #3d3943;

}

.whiteshadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.sidebardivs:hover {
  background-color: #e5e5e5 !important;
}

.sidebardivsdark:hover {
  background-color: #333038 !important;
}


.navlinks {
  font-size: 90%;
}