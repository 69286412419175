#searchbar {
    border-radius: 25px;
    padding: 1%;
    color: #606060;
    background-image: url("../../3.images/images/search-solid.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 4%;
    outline: 0;
    padding-left: 5% !important;
    width: 100%;
    border: 0px;
    line-height: normal;
    background-color: transparent;
}



#headersearchbar {
    border-radius: 12.5px;
    padding: 1%;
    color: #606060;
    background-image: url("../../3.images/images/search-solid.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 5%;
    outline: 0;
    padding-left: 6.5% !important;
    width: 100%;
    line-height: normal;
    border: 1px solid #e5e5e5;
    font-size: 90%;
}

@media only screen and (max-width: 767px) {
    #searchbar {
        border-radius: 25px;
        padding: 1%;
        color: #606060;
        background-image: url("../../3.images/images/search-solid.png");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 8%;
        outline: 0;
        padding-left: 9% !important;
        padding-right: 5% !important;
        width: 100%;
        border: 0px;
        line-height: normal;
        background-color: transparent;
    }
}

#selectextension {
    padding: 0.75%;
    outline: 0;
    color: #606060;
    font-size: 100%;
}

#sidebar {
    position: sticky;
    align-self: flex-start;
    /* <-- this is the fix */
}

.sidebaricons {
    color: #606060 !important;
    cursor: pointer;
    font-size: 90%;
    /* font-weight: bold; */
}

.sidebaricons:hover {
    color: #000 !important;
}

.sidebariconsdiv {
    margin-bottom: 4%;
    margin-right: 10%;
    padding: 5%;
    border-radius: 25px;
}

.iconselected {
    background: #f4f4f4;
}

#sidebarprofile {
    background: #f4f4f4;
    border-radius: 50px;
    font-size: 80%;
    padding: 5%;
}

#sidebarprofile:hover {}

#sidebarlinks {
    margin-left: 7.5%;
}

.dot {
    height: 7.5px;
    width: 7.5px;
    background-color: #606060;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6%;
}

.colouterdiv {
    background-color: white;
    font-size: 90%;
    border: 1px solid #dadce0;
}

.roundwhitebox {
    padding: 2%;
    border-radius: 12.5px;
    background-color: white;
    font-size: 90%;
    border: 1px solid #dadce0;
}

.div1 {
    width: 5%;
    padding-left: 1% !important;
}

.div2 {
    width: 65%;
    padding: 2%;
    padding-left: 1.5% !important;
}

.div3 {
    width: 30%;
    padding: 2%;
    display: flex;
}

[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url(data:image/svg+xml;base64,PD94bWwNCnZlcnNpb249IjEuMCIgZW5jb2Rpbmc9Imlzby04ODU5LTEiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURFNWRzEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTIzLjA1cHgiIGhlaWdodD0iMTIzLjA1cHgiIHZpZXdCb3g9IjAgMCAxMjMuMDUgMTIzLjA1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMjMuMDUgMTIzLjA1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQogICAgPGc+DQogICAgICAgIDxwYXRoIGZpbGw9IiM4MDgwODAiIGQ9Ik0xMjEuMzI1LDEwLjkyNWwtOC41LTguMzk5Yy0yLjMtMi4zLTYuMS0yLjMtOC41LDBsLTQyLjQsNDIuMzk5TDE4LjcyNiwxLjcyNmMtMi4zMDEtMi4zMDEtNi4xMDEtMi4zMDEtOC41LDBsLTguNSw4LjUNCiAgICAgICAgCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCiAgICAgICAgCQljMi4zLDIuMyw2LjEsMi4zLDguNSwwbDguNS04LjVjMi4zLTIuMywyLjMtNi4xLDAtOC41bC00Mi41LTQyLjRsNDIuNC00Mi4zOTlDMTIzLjYyNSwxNy4xMjUsMTIzLjYyNSwxMy4zMjUsMTIxLjMyNSwxMC45MjV6Ii8+DQogICAgPC9nPg0KICAgIDxnPjwvZz4NCiAgICA8Zz48L2c+DQogICAgPGc+PC9nPg0KICAgIDxnPjwvZz4NCiAgICA8Zz48L2c+DQogICAgPGc+PC9nPg0KICAgIDxnPjwvZz4NCiAgICA8Zz48L2c+DQogICAgPGc+PC9nPg0KICAgIDxnPjwvZz4NCiAgICA8Zz48L2c+DQogICAgPGc+PC9nPg0KICAgIDxnPjwvZz4NCiAgICA8Zz48L2c+DQogICAgPGc+PC9nPg0KPC9zdmc+DQo=);
    background-size: 10px 10px;
}

.selecteddiv {
    background: #f4f4f4;
}

.gradient {
    background: #f4f4f4;
    background-position: top;
    background-size: cover;
}

.glass {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2%;
}

.extouter {}

.extinner {
    border: 2px solid #000;
    font-weight: bold;
    color: #000;
    border-radius: 12.5px;
    padding: 2.5%;
    margin: 0%;
    margin-bottom: 2.5%;
    margin-top: 2.5%;
    cursor: pointer;
}

.extp {
    font-size: 90%;
}

.extinnernope {
    border: 2px solid #606060;
    border-radius: 25px;
    margin: 0%;
    padding: 2.5%;
    margin-bottom: 2.5%;
    margin-top: 2.5%;
}

.extpnope {
    font-size: 80%;
    color: #606060;
}

.extselected {
    /* background-color: #7b3fe4; */
    border: 2px solid #7b3fe4;
    color: #7b3fe4;
    font-weight: bold;
}

.extdiv {
    background: #f4f4f4;
    border-radius: 50px;
    padding: 1%;
    color: #606060;
    border: 1px solid #d7d7d7;
}

.extnotdiv {
    background: #e5e5e5;
    border-radius: 50px;
    padding: 1%;
}

#connectdiv {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}